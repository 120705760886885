import React from "react";
import PropTypes from "prop-types";
import Heading from "~components/atoms/heading/heading";
import ButtonTertiary from "~components/molecules/button/button_tertiary/button_tertiary";
import { createInternalLink } from "~utils/link_utils/createInternalLink/createInternalLink";

const JOB_LINK_TEXT = "Back to open roles";
function JobAppliedHeroSection({ header, subheader }) {
  const jobListLink = createInternalLink("/jobs");

  return (
    <>
      <Heading visualLevel="2" seoLevel="1">
        {header}
      </Heading>
      <Heading visualLevel="4" seoLevel="2">
        {subheader}
      </Heading>
      <ButtonTertiary
        color="yellow"
        leadingIcon="icon_chevron_left"
        isInteractive
        isFontBold
        isUnderlined
        isUppercase
        isCentered
        link={jobListLink}
        title={JOB_LINK_TEXT}
      />
    </>
  );
}

JobAppliedHeroSection.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
};

export default JobAppliedHeroSection;
