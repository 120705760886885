import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Heading from "~components/atoms/heading/heading";
import RscHbCardMetadata from "~components/molecules/rsc_hb_card/rsc_hb_card_metadata/rsc_hb_card_metadata";
import {
  addPaddingStyle,
  fullWidthStyle,
  headingWrapperStyle,
  innerStyle,
  textStyle,
} from "./rsc_hb_card_inner.module.scss";

function RscHbCardInner({
  metadata,
  subtitle,
  title,
  children,
  large,
  addPadding,
  fullWidth,
}) {
  const headingVisualLevel = large ? 5 : 6;
  const innerClassNames = classNames(innerStyle, {
    [addPaddingStyle]: addPadding,
    [fullWidthStyle]: fullWidth,
  });

  return (
    <div className={innerClassNames}>
      <div>
        {metadata && <RscHbCardMetadata items={metadata} />}
        {title && (
          <Heading visualLevel={headingVisualLevel} seoLevel="4">
            <div className={headingWrapperStyle}>{title}</div>
          </Heading>
        )}
        {subtitle && (
          <div data-testid="card-description" className={textStyle}>
            {subtitle}
          </div>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
}

RscHbCardInner.defaultProps = {
  subtitle: "",
  children: null,
  metadata: [],
  large: false,
  addPadding: false,
  fullWidth: false,
};

RscHbCardInner.propTypes = {
  large: PropTypes.bool,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  metadata: PropTypes.arrayOf(PropTypes.string),
  addPadding: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default RscHbCardInner;
