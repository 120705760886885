import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Contain from "~components/atoms/contain/contain";
import GridNew from "~components/atoms/grid_new/grid_new";
import SVGBackgroundHeroWrapper from "~components/atoms/svg_background_image/svg_background_image";
import RscHbCardArticle from "~components/molecules/rsc_hb_card/rsc_hb_card_article/rsc_hb_card_article";
import usePageData from "~hooks/use_page_data/use_page_data";
import { ASSET, STORYBLOK_ENTRY } from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";
import JobAppliedContentSection from "./components/job_applied_content_section/job_applied_content_section";
import JobAppliedHeroSection from "./components/job_applied_hero_section/job_applied_hero_section";
import { wrapperStyle } from "./template_job_applied.module.scss";

const GRID_SPLIT = { XXL: 2, M: 2, XS: 1 };
export default function TemplateJobApplied(props) {
  const { pageContext, entryAssets } = usePageData(props);

  const {
    applicationSteps,
    blogItems,
    contentHeader,
    contentText,
    header,
    pagePath,
    subheader,
    subNavData,
  } = pageContext;
  return (
    <TemplateGlobalEntry
      subNavData={subNavData}
      entryAssets={entryAssets}
      headerColor="blue"
      pagePath={pagePath}
      {...props}
    >
      <SVGBackgroundHeroWrapper hasWave background="careers_secondary">
        <Contain small isTextCentered>
          <JobAppliedHeroSection {...{ header, subheader }} />
        </Contain>
      </SVGBackgroundHeroWrapper>
      <div className={wrapperStyle}>
        <Contain>
          <GridNew split={GRID_SPLIT}>
            <JobAppliedContentSection
              {...{ applicationSteps, contentHeader, contentText }}
            />
            {blogItems.map((item) => {
              return (
                <RscHbCardArticle
                  key={item.title}
                  cover={item.cover}
                  link={item.link}
                  title={item.title}
                  subtitle={item.subtitle}
                />
              );
            })}
          </GridNew>
        </Contain>
      </div>
    </TemplateGlobalEntry>
  );
}

TemplateJobApplied.propTypes = {
  data: PropTypes.shape({
    assets: PropTypes.shape({
      nodes: PropTypes.arrayOf(ASSET),
    }),
    story: STORYBLOK_ENTRY,
  }).isRequired,
  pageContext: PropTypes.shape({
    applicationSteps: PropTypes.arrayOf(PropTypes.string),
    pagePath: PropTypes.string,
    header: PropTypes.string,
    subheader: PropTypes.string,
    contentHeader: PropTypes.string,
    contentText: PropTypes.shape({}),
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export const query = graphql`
  fragment jobAppliedPageStory on Query {
    story: storyblokEntry(id: { eq: $id }) {
      assets {
        ...asset
      }
      content
      full_slug
      group_id
      alternates {
        full_slug
      }
    }
  }

  query jobAppliedPageQuery(
    $id: String
    $langRegex: String
    $allCoverIDs: [String]
  ) {
    ...jobAppliedPageStory
    ...global
    ...resourceHubCoverAssets
  }
`;
