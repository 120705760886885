import React from "react";
import PropTypes from "prop-types";
import Heading from "~components/atoms/heading/heading";
import RichText from "~components/molecules/rich_text/rich_text";
import Stepper from "~components/molecules/stepper/stepper";
import { contentContainerStyle } from "./job_applied_content_section.module.scss";

const JobAppliedContentSection = ({
  applicationSteps,
  contentHeader,
  contentText,
}) => {
  return (
    <div className={contentContainerStyle}>
      <Stepper
        length={3}
        activeStep={3}
        textArray={applicationSteps}
        isComplete
      />
      <Heading visualLevel="3" seoLevel="3">
        {contentHeader}
      </Heading>
      <RichText size="small" text={contentText} />
    </div>
  );
};

JobAppliedContentSection.propTypes = {
  applicationSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
  contentHeader: PropTypes.string.isRequired,
  contentText: PropTypes.shape({}).isRequired,
};

export default JobAppliedContentSection;
