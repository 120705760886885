import PropTypes from "prop-types";
import React from "react";

import { metadataStyle } from "./rsc_hb_card_metadata.module.scss";

function RscHbCardMetadata({ items }) {
  return (
    <div data-testid="rsc-hb-card-metadata" className={metadataStyle}>
      {items.join(" • ")}
    </div>
  );
}

RscHbCardMetadata.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
};

RscHbCardMetadata.defaultProps = {
  items: null,
};

export default RscHbCardMetadata;
