import React, { useCallback } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Card from "~components/atoms/card/card";
import DebouncedFocusWrapper from "~components/utils/debounced_focus_wrapper/debounced_focus_wrapper";
import LinkWrapper from "~components/utils/link_wrapper/link_wrapper";
import { LINK } from "../../../../prop_types";
import {
  gridWrapperStyle,
  isLandscapeStyle,
} from "./rsc_hub_card_wrapper.module.scss";

function RscHbCardWrapper({
  link,
  children,
  isActive,
  setIsActive,
  hasCard,

  isLandscape,
  ...rest
}) {
  const setActive = useCallback(() => {
    return setIsActive(true);
  }, [setIsActive]);
  const setInactive = useCallback(() => {
    return setIsActive(false);
  }, [setIsActive]);

  const noCardClassNames = classNames(gridWrapperStyle, {
    [isLandscapeStyle]: isLandscape,
  });

  if (hasCard) {
    return (
      <div {...rest}>
        <LinkWrapper block stretch link={link}>
          <Card hoverState isFullWidth isRaised={isActive} stretch>
            <DebouncedFocusWrapper
              callbackWhenFocused={setActive}
              callbackWhenBlurred={setInactive}
            >
              {children}
            </DebouncedFocusWrapper>
          </Card>
        </LinkWrapper>
      </div>
    );
  }

  return (
    <div {...rest}>
      <LinkWrapper block stretch link={link}>
        <DebouncedFocusWrapper
          callbackWhenFocused={setActive}
          callbackWhenBlurred={setInactive}
        >
          <div className={noCardClassNames}>{children}</div>
        </DebouncedFocusWrapper>
      </LinkWrapper>
    </div>
  );
}

RscHbCardWrapper.propTypes = {
  hasCard: PropTypes.bool,
  isLandscape: PropTypes.bool,
  link: LINK.isRequired,
  children: PropTypes.node.isRequired,
  setIsActive: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};
RscHbCardWrapper.defaultProps = {
  isLandscape: false,
  isActive: false,
  hasCard: false,
};

export default RscHbCardWrapper;
